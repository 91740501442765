* {
  font-family: "Archivo", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

img {
  image-rendering: -webkit-optimize-contrast;
}

img::selection {
  background-color: transparent;
}

body {
  max-width: 100%;
  overflow-x: hidden;
}

a,
i {
  text-decoration: none;
  /* cursor: none; */
}

/* classes para girar os selos ------------------------------------------------ */

.seloMeiucaCo {
  position: absolute;
  right: 0;
  top: -56px;
  width: 80px;
  height: 80px;
}

@media screen and (min-width: 1024px) {
  .seloMeiucaCo {
    width: 160px;
    height: 160px;
    top: -86px;
  }
}

@keyframes loading {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(-360deg);
  }
}

.is-animating {
  animation: loading 15s linear infinite;
}

/* classe para estilizar o card da section carousel da página de projetos---- */
